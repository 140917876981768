<template>
  <section class="s-carousel">
    <div class="s-carousel-text" data-effect="block">
      <h3 class="f-st" style>
        <div
          style="display: block; text-align: start; position: relative; transform-origin: 0px 0px; transform: translate3d(0px, 0px, 0px); opacity: 1;"
        >Spotlight</div>
      </h3>
      <h2 class="f-hm" style>
        <div
          style="display: block; text-align: start; position: relative; transform-origin: 0px 0px; transform: translate3d(0px, 0px, 0px); opacity: 1;"
        >Explore our</div>
        <div
          style="display: block; text-align: start; position: relative; transform-origin: 0px 0px; transform: translate3d(0px, 0px, 0px); opacity: 1;"
        >
          seasonal
          <em>highlights</em>
        </div>
      </h2>
    </div>
    <div class="s-carousel-nav">
      <button @click="active = !active" class="s-carousel-prev">
        Previous
        <svg viewBox="0 0 6 10">
          <path
            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
          />
        </svg>
        <svg viewBox="0 0 6 10">
          <path
            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
          />
        </svg>
      </button>
      <button @click="active = !active" class="s-carousel-next">
        Next
        <svg viewBox="0 0 6 10">
          <path
            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
          />
        </svg>
        <svg viewBox="0 0 6 10">
          <path
            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
          />
        </svg>
      </button>
    </div>
    <div class="s-carousel-container" style="height: 775px;">
      <div class="s-carousel-slide"  v-bind:class="{active: !active}">
        <div class="s-carousel-item" v-for="(itm,index) in product" :key="index" v-if="index % 2 == 0">
          <a
            :href="'#/productdetail/'+ itm.collections[0].slug + '/' + itm.id"
            data-cursor="more"
          >
            <div class="s-carousel-thumbnail" v-if="itm.images">
              <img v-if="itm && itm.images && itm.images[0].image && itm.images[0].image[0].src"
                :src="$store.state.file_url + itm.images[0].image[0].src + '?alt=media'"
                alt
              />
            </div>
            <div class="s-carousel-info">
              <h3 class="f-st">{{trimName(itm.collections[0].slug)}}</h3>
              <h4 class="f-hp">{{itm.name}}</h4>
            </div>
          </a>
        </div>
      </div>
      <div class="s-carousel-slide"  v-bind:class="{active: active}">
        <div class="s-carousel-item" v-for="(itm,index) in product" :key="index" v-if="index % 2 != 0">
          <a :href="'#/productdetail/'+ itm.collections[0].slug+ '/' + itm.id" data-cursor="more">
            <div class="s-carousel-thumbnail">
              <img v-if="itm && itm.images && itm.images[0].image && itm.images[0].image[0].src"
                :src="$store.state.file_url + itm.images[0].image[0].src + '?alt=media'"
                alt
              />
            </div>
            <div class="s-carousel-info">
              <h3 class="f-st">{{trimName(itm.collections[0].slug)}}</h3>
              <h4 class="f-hp">{{itm.name}}</h4>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="s-carousel-background" data-background="#ffffff"></div>
  </section>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            prev: true,
            next: false,
            active: false,
            product: {}
        }
    },
    created(){
        this.init()
    },
    methods:{
      init(){
        axios
        .post(
          this.$store.state.api + "product/_search",
            {
              from: 0,
              size: 5000,
              query: {
                bool: {
                  must: [
                    {
                      match_phrase: {
                        event: this.$store.state.event
                      }
                    },
                      {
                      match_phrase: {
                        featured_products: "Yes"
                      }
                    }
                  ]
                }
              }
            }
        ).then(response => {
            this.product = {}
            var data = response.data.hits.hits
            for(var ind in data){
                this.product[ind] = data[ind]._source
                this.product[ind].id = data[ind]._id
            }
        })
      },
      trimName(name) {
      if (name)
        return name
          .replace(new RegExp("_"), " ")
    },
    },
};
</script>

<style scoped>
section, figure{
    /* margin-bottom: 0; */
}
.active{
    visibility: visible;
}
.s-carousel-prev,
.s-carousel-next {
  width: 3.375vw;
  height: 3.375vw;
  overflow: hidden;
  position: relative;
  text-indent: -999vw;
  border-radius: 100%;
  background-color: #ffff;
  transition: background-color 0.4s ease-out;
}
@media only screen and (max-width: 580px){
  .s-carousel-container{
    height: 230vh !important;
  }
  .s-carousel-slide:nth-child(3n+1) .s-carousel-item:nth-child(3n+3){
    margin-left: 0;
  }
  .s-carousel-item{
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 411px){
  .s-carousel-container{
    height: 215vh !important;
  }
}
@media only screen and (max-width: 375px){
  .s-carousel-container{
    height: 220vh !important;
  }
}
@media only screen and (min-height: 812px){
  .s-carousel-container{
    /* height: 180vh !important; */
  }
}
/* @media only screen and (min-height: 1366px){
  .s-carousel-container{
    height: 30vh !important;
  }
  .s-instagram-figure{
    height: 38vh;
  }
} */
/* @media only screen and (min-width: 768px){
  .s-carousel-container{
    height: 30vh !important;
  }
  .s-instagram-figure{
    height: 38vh;
  }
  .s-instagram{
    margin-bottom: 0;  
  }
  .s-instagram-figure{
    height: 38vh !important;
  }
} */
</style>